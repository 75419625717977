<template>
  <div>
    <vx-card class="mt-base" title="Las evidencias." v-if="carousel.length > 0">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>Consulta las diferentes evidencias fotográficas de la instalación del proyecto que solicitaste.
            ¿Qué se siente saber que tu participación fue fundamental para que esta semilla creciera en un brillante girasol?
            ¡Estamos limpiando al planeta!</p>
        </div>
      </div>
      <div class="vx-row mt-6">
        <div class="vx-col w-full">
          <image-carousel :images="carousel" v-if="carousel.length > 0" />
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ImageCarousel from "@components/shared/ImageCarousel";
const REGEX = /(?:\.([^.]+))?$/;
const imageExtensions = ['png', 'jpg', 'jpeg', 'bmp'];
export default {
  name: "ApplicantProjectEvidences",
  props: ['projectId'],
  components: {ImageCarousel},
  data(){
    return {
      failed: false,
      images: [],
      splitsImages: [],
      carousel: [],
    }
  },
  async beforeMount() {
    await this.getEvidences();
  },
  methods: {
    async getEvidences(){
      try {
        const response = await  axios.get('/api/v1/applicant/project/' + this.projectId + '/imagenes');
        this.images = response.data.images;
        this.splitsImages = response.data.splitsImages;

        // general url
        this.images.forEach(img => {
          if(this.validateImageExtension(img.storage_file)){
            let url = `${this.ApiDomain}/storagev3/documents/view_document_file/${img.id}/project/${this.projectId}?access_token=${this.AccessToken}`;
            this.carousel.push({index: img.id, url: url, filename: img.storage_file});
          }
        });
      }
      catch (e) {
        this.failed = true;
      }
    },
    validateImageExtension(filename){
      let ext = REGEX.exec(filename)[1];
      let dext = ext.toLowerCase();
      return imageExtensions.includes(dext);
    },
  },
}
</script>

<style scoped>

</style>