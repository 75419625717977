const ProjectStatusEnum = Object.freeze({
    DEFAULT_VALUE: 0,
    LEAD: 1,
    IN_REVISION: 2,
    PRE_APPROVE: 3,
    APPROVE: 4,
    FUNDING: 5,
    SIGNING_CONTRACTS: 6,
    INSTALLING: 7,
    DELIVERED: 8,
    REJECTED: 9,
    REFUND: 10
});
export default ProjectStatusEnum;