<template>
  <div>
    <big-title v-if="isMounted">
      {{ alias }} (ID {{ id }}).
    </big-title>

    <vs-tabs class="mt-1" color="primary" ref="tabs" :value="tabIndex">
      <vs-tab label="La semilla" icon="sports_rugby">
        <applicant-credit-header
          v-if="isMounted"
          :project-data="projectData"
          :project-id="id"
          :alias="alias" />

        <applicant-seed-tabs v-if="isMounted" :project-data="projectData" :project-id="id"/>
      </vs-tab>

      <vs-tab label="El Girasol" icon="local_florist">
        <applicant-sunflower v-if="isMounted" :project-data="projectData" :project-id="id" :alias="alias" />
      </vs-tab>
    </vs-tabs>

  </div>
</template>

<script>
import ApplicantCreditHeader from "@components/applicant/projects/detail/ApplicantCreditHeader";
import ApplicantSeedTabs from "@components/applicant/projects/detail/ApplicantSeedTabs";
import ApplicantSunflower from "@components/applicant/projects/detail/ApplicantSunflower";
export default {
  name: "ApplicantProjectDetail",
  props: ["id"],
  components: {
    ApplicantCreditHeader,
    ApplicantSeedTabs,
    ApplicantSunflower
  },
  data(){
    return {
      alias: null,
      typeName: null,
      projectData: null,
      isMounted: false,
      tabIndex: 0,
      sunflowerDisabled: true
    }
  },
  async mounted(){
    this.initComponent();
  },
  watch: { 
    id: async function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.initComponent();
      }
    }
  },
  methods: {
    async initComponent(){
      this.isMounted = false
      this.showLoading(true)
      const auth = await this.authorizeViewProject();
      if(!auth){
        await this.$router.replace({name: 'inicioSolicitante'});
        this.showLoading(false)
        return;
      }
      await this.getProjectInfo();
      this.setDefaultView();
      this.isMounted = true
      this.showLoading(false)
    },
    async authorizeViewProject(){
      try {
        await axios.get(`api/v1/applicant/${this.ApplicantId}/authorizeViewProject/${this.id}`);
        return true;
      }
      catch (e){
        return false;
      }
    },
    async getProjectInfo(){
      try {
        const res = await axios.get(`api/projects/single/${this.id}`);
        this.projectData = res.data;
        this.alias = this.projectData.project.alias;
        this.typeName = this.projectData.project.name;
      }
      catch (e){
        this.warn(e);
      }
    },
    setDefaultView(){
      let tab = null;
      const status = this.projectData.project.status;
      if(status === 4){
        tab = 0;
      }else{
        if (status >= 5) {
          tab = 1;
        }
      }
      this.$refs.tabs.activeChild(tab, true);
    },
  }
}
</script>
