<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="card-sub-title mb-3">Los eventos de tu proyecto.</p>
          <!-- <p v-if="currentEvent">{{ humanDateFormat(currentEvent.created_at) }}:  {{ currentEvent.event }}</p> -->
          <p>
            A lo largo de tu crédito ocurren diferentes eventos relacionados a la instalación y cobranza del proyecto. 
            Estos eventos se registran en dos bitácoras llamadas “Eventos del proyecto” y “Eventos de cobranza” que mensualmente 
            leen los inversionistas involucrados en tu crédito para conocer el estado del préstamo que te hicieron y con el cual tu 
            proyecto se hizo realidad. Consulta estos mensajes a continuación.</p>
        </div>
      </div>
      <div class="vx-row mt-base">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button color="primary" class="sm:px-base px-4 mr-2 mb-3" @click="showProjectEvents">Eventos del proyecto</vs-button>
            <vs-button color="primary" class="sm:px-base px-4 mr-2 mb-3" @click="showProjectCashingtEvents">Eventos de cobranza</vs-button>
            <vs-button color="dark" class="sm:px-base px-4 mb-3" @click="showPaymentsPlan">Plan de pagos</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <vx-card title="Credenciales de monitoreo" class="mt-base" v-if="monitoringUrl && monitoringUser && monitoringPassword">
      <div class="vx-row">
        <div class="vx-col lg:w-1/3 w-full mb-5">
          <vs-input
            class="w-full"
            label="Usuario"
            name="user"
            readonly="true"
            v-model.lazy="monitoringUser"
            icon-pack="feather"
          />
        </div>
        <div class="vx-col lg:w-1/3 w-full mb-5">
          <vx-input-group class="w-full">
              <vs-input
                class="w-full"
                label="Contraseña"
                name="password"
                readonly="true"
                v-model.lazy="monitoringPassword"
                :type="passInputType"
                icon-pack="feather"
              />
              <template slot="append">
                <div class="append-text btn-addon mt-6" style="height:40px;">
                  <vs-button color="dark" @click="togglePassInputType()" icon-pack="feather" :icon="passInputIcon"></vs-button>
                </div>
              </template>
          </vx-input-group>
        </div>
        <div class="vx-col lg:w-1/3 w-full mb-5">
          <label class="vs-input--label"></label><br>
          <vs-button type="border" class="border-2" @click="goToMonitoringPage" target="blank">Visitar web</vs-button>
        </div>
      </div>
    </vx-card>

    <div class="mt-4">
      <applicant-project-evidences :project-id="projectId" />
    </div>

    <div class="mt-4">
      <applicant-project-contracts :project-id="projectId" />
    </div>

    <!-- Popup para eventos de proyecto -->
    <vs-popup :active.sync="popUpEventsActive" :title="alias" class="extended-popup">
      <applicant-project-events :project-id="projectId" />
    </vs-popup>

    <!-- Popup para eventos de cobranza -->
    <vs-popup :active.sync="popUpCashingEventsActive" :title="alias" class="extended-popup">
      <applicant-project-cashing-events :project-id="projectId" />
    </vs-popup>

    <!-- Popup para plan de pagos -->
    <vs-popup :active.sync="popUpPaymentsActive" :title="alias" class="max-width-popup header-primary">
      <applicant-payments-plan :project-id="projectId" />
    </vs-popup>

  </div>
</template>

<script>
import projectStatusesHelper from "@mixins/projectStatusesHelper";
import creditStatusesHelper from "@mixins/creditStatusesHelper";
import ApplicantPaymentsPlan from "@components/applicant/projects/detail/ApplicantPaymentsPlan";
import ApplicantProjectEvents from "@components/applicant/projects/detail/ApplicantProjectEvents";
import ApplicantProjectCashingEvents from "@components/applicant/projects/detail/ApplicantProjectCashingEvents";
import ApplicantProjectEvidences from "@components/applicant/projects/detail/ApplicantProjectEvidences";
import ApplicantProjectContracts from "@components/applicant/projects/detail/ApplicantProjectContracts";
import dateHelper from "@mixins/dateHelper";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
const httpPattern = /^https[s]*:\/\/[\w]+/i;
export default {
  name: "ApplicantSunflower",
  props: {
    projectData: { type: Object, required: true },
    projectId: { type: [String,Number], required: true },
    alias: { type: [String], required: true },
  },
  data(){
    return {
      popUpEventsActive: false,
      popUpCashingEventsActive: false,
      popUpPaymentsActive: false,
      events: [],
      monitoringUrl: null,
      monitoringUser: null,
      monitoringPassword: null,
      passInputPassMode: true,
      extraParamsForLog: {}
    }
  },
  components: {
    ApplicantPaymentsPlan,
    ApplicantProjectEvents,
    ApplicantProjectCashingEvents,
    ApplicantProjectEvidences,
    ApplicantProjectContracts
  },
  mixins: [projectStatusesHelper, creditStatusesHelper, dateHelper],
  computed: {
    currentStatus(){
      if(this.projectData.project.status === 7) {
        let nn = this.projectStatusName(this.projectData.project.status);
        nn += ". Pago: ";
        nn += this.creditStatusName(this.projectData.finance.asset_status, this.projectData.finance.credit_status);
        return nn;
      }
      else {
        return this.projectStatusName(this.projectData.project.status);
      }
    },
    currentEvent(){
      if(this.events.length && this.events.length > 0){
        return this.events[0];
      }
      else{
        return null;
      }
    },
    currentDescription(){
      if(this.events.length && this.events.length > 0){
        return this.events[0].event;
      }
      else{
        return null;
      }
    },
    isPopupActive: {
      get() {
        return this.$store.state.isProjectDetailPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE", val);
      }
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
    passInputType(){
      return this.passInputPassMode ? "password" : "text";
    },
  },
  beforeMount(){
    this.extraParamsForLog = {
      project_id: this.projectId
    };
  },
  async mounted(){
    this.showLoading(true)
    if (this.projectData.project.status === 8) {
      await this.getMonitoringData();
    }
    this.showLoading(false)
  },
  methods: {
    showPaymentsPlan(){
      // se genera log de accion
      this.saveApplicantLogAction("LC20-21",this.extraParamsForLog);
      this.popUpPaymentsActive = true;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    showProjectEvents(){
      // se genera log de accion
      this.saveApplicantLogAction("LC20-22",this.extraParamsForLog);
      this.popUpEventsActive = true;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    showProjectCashingtEvents(){
      // se genera log de accion
      this.saveApplicantLogAction("LC20-20",this.extraParamsForLog);
      this.popUpCashingEventsActive = true;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    backToDetail(){
      this.popUpEventsActive = false;
      this.popUpCashingEventsActive = false;
      this.popUpPaymentsActive = false;
    },
    async getMonitoringData(){
      try{
        const response = await axios.get(`api/v1/applicant/project/${this.$props.projectId}/monitoreo`);
        this.monitoringUrl = response.data.monitoring_url;
        this.monitoringUser = response.data.monitoring_user;
        this.monitoringPassword = response.data.monitoring_password;
      }
      catch (e) {
      }
    },
    formatUrl(url){
      if(!url){
        return null;
      }
      if(!httpPattern.exec(url)){
        return "https://" + url;
      }
      else{
        return url;
      }
    },
    goToMonitoringPage(){
      // se genera log de accion
      this.saveApplicantLogAction("LC20-37",this.extraParamsForLog);
      let monitoringUrl = this.formatUrl(this.monitoringUrl);
      window.open(monitoringUrl, '_blank');
    },
    togglePassInputType(){
      this.passInputPassMode = !this.passInputPassMode;
    },
  }
}
</script>