import ProjectStatusEnum from "@/modules/enums/ProjectStatusEnum";

export default {
  methods: {
    projectStatusName(status){
      switch (status){
        case ProjectStatusEnum.DEFAULT_VALUE:
          return 'No definido';
        case ProjectStatusEnum.LEAD:
          return 'Borrador';
        case ProjectStatusEnum.IN_REVISION:
          return 'En revisión';
        case ProjectStatusEnum.PRE_APPROVE:
          return 'Pre-aprobado';
        case ProjectStatusEnum.APPROVE:
          return 'Aprobado';
        case ProjectStatusEnum.FUNDING:
          return 'En fondeo';
        case ProjectStatusEnum.SIGNING_CONTRACTS:
          return 'En firma de contratos';
        case ProjectStatusEnum.INSTALLING:
          return 'En instalación';
        case ProjectStatusEnum.DELIVERED:
          return 'Entregado';
        case ProjectStatusEnum.REJECTED:
          return 'Rechazado';
        case ProjectStatusEnum.REFUND:
          return 'Reembolsado';
        default:
          return 'No definido';
      }
    },
    projecStatusClass(status){
      switch (status){
        case ProjectStatusEnum.FUNDING:
        case ProjectStatusEnum.SIGNING_CONTRACTS:
          return 'status-funding';
        case ProjectStatusEnum.INSTALLING:
          return 'status-running';
        default:
          return 'status-unknown';
      }
    },
    projectStatusColor(status) {
      switch (status){
        case ProjectStatusEnum.FUNDING:
          return '#28DE18';
        case ProjectStatusEnum.SIGNING_CONTRACTS:
          return '#f68141';
        case ProjectStatusEnum.INSTALLING:
          return '#774e6a';
        default:
          return '#3B3A3E'; // negro
      }
    }
  }
}