import CreditStatusEnum from "@/modules/enums/CreditStatusEnum";
import AssetStatusEnum from "@/modules/enums/AssetStatusEnum";
export default {
    methods: {
        assetCreditStatusName(assetStatus, creditStatus){
            let name = "";
            switch (assetStatus){
                case AssetStatusEnum.EN_FONDEO:
                    name = "Compromiso de inversión";
                    break;
                case AssetStatusEnum.VIGENTE:
                    switch (creditStatus) {
                        case CreditStatusEnum.AL_CORRIENTE:
                        case CreditStatusEnum.EN_PERIODO_GRACIA:
                            name = "Al corriente";
                            break;
                        case CreditStatusEnum.RETRASADO_11_30:
                        case CreditStatusEnum.RETRASADO_31_60:
                        case CreditStatusEnum.RETRASADO_61_90:
                            name = "Retrasado";
                            break;
                        case CreditStatusEnum.VENCIDO:
                            name = "Vencido";
                            break;
                        default:
                            break;
                    }
                    break;
                case AssetStatusEnum.OTROS_BIENES_ADMINISTRACION:
                case AssetStatusEnum.BIENES_MANDATO:
                    name = "Garantías recuperadas";
                    break;
                case AssetStatusEnum.PAGADO:
                    name = "Pagado";
                    break;
                case AssetStatusEnum.QUEBRANTADO_PARCIALMENTE:
                    name = "Quebrantado parcialmente";
                    break;
                case AssetStatusEnum.IRRECUPERABLE:
                    name = "Irrecuperable";
                    break;
                default:
                    name = "Desconocido";
                    break;
            }
            return name;
        },
        creditStatusName(assetStatus, creditStatus){
            let name = "";
            switch (assetStatus){
                case AssetStatusEnum.EN_FONDEO:
                    name = "Compromiso de inversión";
                    break;
                case AssetStatusEnum.VIGENTE:
                    switch (creditStatus) {
                        case CreditStatusEnum.AL_CORRIENTE:
                        case CreditStatusEnum.EN_PERIODO_GRACIA:
                            name = "Al corriente";
                            break;
                        case CreditStatusEnum.RETRASADO_11_30:
                        case CreditStatusEnum.RETRASADO_31_60:
                        case CreditStatusEnum.RETRASADO_61_90:
                            name = "Retrasado";
                            break;
                        case CreditStatusEnum.VENCIDO:
                            name = "Vencido";
                            break;
                        default:
                            break;
                    }
                    break;
                case AssetStatusEnum.OTROS_BIENES_ADMINISTRACION:
                case AssetStatusEnum.BIENES_MANDATO:
                    name = "Garantías recuperadas";
                    break;
                case AssetStatusEnum.PAGADO:
                    name = "Pagado";
                    break;
                case AssetStatusEnum.QUEBRANTADO_PARCIALMENTE:
                    name = "Quebrantado parcialmente";
                    break;
                case AssetStatusEnum.IRRECUPERABLE:
                    name = "Irrecuperable";
                    break;
                default:
                    name = "Desconocido";
                    break;
            }
            return name;
        },
        explicitCreditStatusName(assetStatus, creditStatus){
            let name = "";
            switch (assetStatus){
                case AssetStatusEnum.EN_FONDEO:
                    name = "Compromiso de inversión";
                    break;
                case AssetStatusEnum.VIGENTE:
                    switch (creditStatus) {
                        case CreditStatusEnum.AL_CORRIENTE:
                            name = "Al corriente";
                            break;
                        case CreditStatusEnum.EN_PERIODO_GRACIA:
                            name = "En periodo de gracia";
                            break;
                        case CreditStatusEnum.RETRASADO_11_30:
                            name = "Retrasado (11 a 30 días)";
                            break;
                        case CreditStatusEnum.RETRASADO_31_60:
                            name = "Retrasado (31 a 60 días)";
                            break;
                        case CreditStatusEnum.RETRASADO_61_90:
                            name = "Retrasado (61 a 90 días)";
                            break;
                        case CreditStatusEnum.VENCIDO:
                            name = "Vencido";
                            break;
                        default:
                            break;
                    }
                    break;
                case AssetStatusEnum.OTROS_BIENES_ADMINISTRACION:
                case AssetStatusEnum.BIENES_MANDATO:
                    name = "Garantías recuperadas";
                    break;
                case AssetStatusEnum.PAGADO:
                    name = "Pagado";
                    break;
                case AssetStatusEnum.QUEBRANTADO_PARCIALMENTE:
                    name = "Quebrantado parcialmente";
                    break;
                case AssetStatusEnum.IRRECUPERABLE:
                    name = "Irrecuperable";
                    break;
                default:
                    name = "Desconocido";
                    break;
            }
            return name;
        },
        creditStatusOrder(assetStatus, creditStatus){
            switch (assetStatus){
                case AssetStatusEnum.EN_FONDEO:
                    return 10;
                case AssetStatusEnum.VIGENTE:
                    switch (creditStatus) {
                        case CreditStatusEnum.AL_CORRIENTE:
                            return 12;
                        case CreditStatusEnum.EN_PERIODO_GRACIA:
                            return 13;
                        case CreditStatusEnum.RETRASADO_11_30:
                            return 15;
                        case CreditStatusEnum.RETRASADO_31_60:
                            return 16;
                        case CreditStatusEnum.RETRASADO_61_90:
                            return 17;
                        case CreditStatusEnum.VENCIDO:
                            return 21;
                        default:
                            return 99;
                    }
                case AssetStatusEnum.OTROS_BIENES_ADMINISTRACION:
                case AssetStatusEnum.BIENES_MANDATO:
                    return 19;
                case AssetStatusEnum.PAGADO:
                    return 50;
                case AssetStatusEnum.QUEBRANTADO_PARCIALMENTE:
                    return 18;
                case AssetStatusEnum.IRRECUPERABLE:
                    return 18;
                default:
                    return 99;
            }
        },
        creditStatusClass(assetStatus, creditStatus){
            switch (assetStatus){
                case AssetStatusEnum.EN_FONDEO:
                    return 'status-funding';
                case AssetStatusEnum.VIGENTE:
                    switch (creditStatus) {
                        case CreditStatusEnum.AL_CORRIENTE:
                        case CreditStatusEnum.EN_PERIODO_GRACIA:
                            return 'status-ok';
                        case CreditStatusEnum.RETRASADO_11_30:
                        case CreditStatusEnum.RETRASADO_31_60:
                        case CreditStatusEnum.RETRASADO_61_90:
                        case CreditStatusEnum.VENCIDO:
                            return 'status-warning';
                        default:
                            return 99;
                    }
                case AssetStatusEnum.OTROS_BIENES_ADMINISTRACION:
                case AssetStatusEnum.BIENES_MANDATO:
                    return 'status-running';
                case AssetStatusEnum.PAGADO:
                    return 'status-finished';
                case AssetStatusEnum.QUEBRANTADO_PARCIALMENTE:
                case AssetStatusEnum.IRRECUPERABLE:
                    return 'status-warning';
                default:
                    return 'status-unknown';
            }
        },
        creditStatusColor(assetStatus, creditStatus) {
            switch (assetStatus){
                case AssetStatusEnum.EN_FONDEO:
                    return '#757575';
                case AssetStatusEnum.VIGENTE:
                    switch (creditStatus) {
                        case CreditStatusEnum.AL_CORRIENTE:
                        case CreditStatusEnum.EN_PERIODO_GRACIA:
                            return '#6AB547';
                        case CreditStatusEnum.RETRASADO_11_30:
                        case CreditStatusEnum.RETRASADO_31_60:
                        case CreditStatusEnum.RETRASADO_61_90:
                            return '#EC9F05';
                        case CreditStatusEnum.VENCIDO:
                            return '#E84855';
                        default:
                            return '#3B3A3E';
                    }
                case AssetStatusEnum.PAGADO:
                    return '#3C896D';
                default:
                    return '#3B3A3E';
            }
        }
    }
}
