<template>
  <div id="payments-plan-popup">
    <div v-if="isMounted">
      <vs-tabs v-model="optionActiveTab" alignment="fixed" class="tabs-shadow-none">
          <vs-tab key="payments" label="Plan de pagos">
              <div class="tab-text">
                  <div class="row">
                      <div class="col-md-12">
                          <vs-table ref="paymentsPlanTable" :data="payments.monthlyPayments" stripe noDataText="No hay registros disponibles">
                            <template slot="thead">
                                <vs-th class="bold">Mes</vs-th>
                                <vs-th class="bold">Fecha de pago</vs-th>
                                <vs-th class="bold">Pago</vs-th>
                                <!-- <vs-th class="bold">Pago efectivo</vs-th> -->
                                <vs-th class="bold">Saldo inicial</vs-th>
                                <vs-th class="bold">Amortización</vs-th>
                                <vs-th class="bold">Intereses</vs-th>
                                <vs-th class="bold">IVA</vs-th>
                                <!-- <vs-th class="bold">Comisión</vs-th> -->
                                <vs-th class="bold">Saldo final</vs-th>
                                <vs-th class="bold"></vs-th>
                            </template>
                            <template slot-scope="{data}">
                              <vs-tr :key="indextr" v-for="(payment, indextr) in data">
                                <vs-td>{{indextr + 1}}</vs-td>
                                <vs-td class="bold">{{payment.payment_date | human_date}}</vs-td>
                                <vs-td>${{formatPrice(payment.payment)}}</vs-td>
                                <!-- <vs-td class="table-number bold" style="color:red">${{formatPrice(payment.cash_payment)}}</vs-td> -->
                                <vs-td>${{formatPrice(payment.initial_debt)}}</vs-td>
                                <vs-td>${{formatPrice(payment.amortization)}}</vs-td>
                                <vs-td>${{formatPrice(payment.interest)}}</vs-td>
                                <vs-td>${{formatPrice(payment.tax)}}</vs-td>
                                <!-- <vs-td class="table-number">${{formatPrice(payment.client_comission)}}</vs-td> -->
                                <vs-td>${{formatPrice(payment.final_debt)}}</vs-td>
                                <vs-td class="bold" v-if="payment.paid == 1" style="text-align:center; color:ForestGreen;">Pagado</vs-td>
                                <vs-td class="bold" v-else-if="payment.with_due" style="text-align:center; color:red;">Retrasado</vs-td>
                                <vs-td class="bold" v-else style="text-align:center;color:DarkOrange;">Por pagar</vs-td>
                              </vs-tr>
                              <vs-tr>
                                <vs-td class="bold">Total</vs-td>
                                <vs-td></vs-td>
                                <vs-td class="bold">${{formatPrice(payments.totalMonthlyPayments.payment)}}</vs-td>
                                <vs-td></vs-td>
                                <vs-td class="bold">${{formatPrice(payments.totalMonthlyPayments.amortization)}}</vs-td>
                                <vs-td class="bold">${{formatPrice(payments.totalMonthlyPayments.interest)}}</vs-td>
                                <vs-td class="bold">${{formatPrice(payments.totalMonthlyPayments.tax)}}</vs-td>
                                <vs-td></vs-td>
                                <!-- <vs-td class="table-number bold">${{formatPrice(payments.totalMonthlyPayments.client_comission)}}</vs-td> -->
                                <vs-td></vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                      </div>
                  </div>
              </div>
          </vs-tab>
          <vs-tab key="advance_payments" label="Pagos anticipados parciales">
            <vs-table ref="advancePaymentsPlanTable" :data="payments.advanceCapitalPayments" stripe noDataText="Aquí aparecerán los pagos anticipados parciales que realices.">
              <template slot="thead">
                  <vs-th class="bold">Fecha del pago anticipado</vs-th>
                  <vs-th class="bold" v-if="masterGlobal">Proyecto</vs-th>
                  <vs-th class="bold">Monto del pago total</vs-th>
                  <vs-th class="bold">Aplicado a amortización</vs-th>
                  <vs-th class="bold">Monto de pago del periodo anterior</vs-th>
                  <vs-th class="bold">Monto de pago del periodo nuevo</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(payment, indextr) in data">
                  <vs-td class="bold">{{ humanDateFormat(payment.created_at)}}</vs-td>
                  <vs-td class="bold" v-if="masterGlobal">{{payment.alias}}</vs-td>
                  <vs-td>${{formatPrice(payment.payment)}}</vs-td>
                  <vs-td>${{formatPrice(payment.amortization)}}</vs-td>
                  <vs-td>${{formatPrice(payment.old_period_payment)}}</vs-td>
                  <vs-td class="text-primary bold">${{formatPrice(payment.new_period_payment)}}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import {formatPrice} from '@/filters'
import dateHelper from "@mixins/dateHelper";
import formatHelper from "@mixins/formatHelper";
import * as moment from 'moment'
export default {
  name: "ApplicantPaymentsPlan",
  props: {
    projectId: { type: [String,Number], required: true},
    masterGlobal: { type: [Boolean], required: false, default: false}
  },
  mixins: [dateHelper, formatHelper],
  data(){
    return {
      payments: {
          monthlyPayments:[],
          advanceCapitalPayments:[],
      },
      optionActiveTab:0,
      isMounted: false,
      failed: false,
    }
  },
  async mounted() {
    this.showLoading(true)
    this.isMounted = false
    await this.getPaymentsInfo();
    this.isMounted = true
    this.showLoading(false)
  },
    filters: {
        human_date(date) {
            return moment(date).lang("es").format("D MMM YYYY");
        }
    },
  methods: {
    formatPrice,
    async getPaymentsInfo(){
      try{
        let response = null; 
        if (this.$props.masterGlobal) {
          response = await axios.get(`api/v1/applicant/${this.ApplicantId}/pagosGlobal`);
        }
        else{
          response = await axios.get(`api/v1/applicant/project/${this.$props.projectId}/pagos`);
        }
        this.payments = response.data.payments;
      }
      catch (e) {
        this.failed = true;
      }
    }
  }
}
</script>