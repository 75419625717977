<template>
  <vx-card class="px-1" >
    <div class="vx-row">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">El crédito.</p>
        <p>
          Consulta y evalúa las condiciones generales del crédito, la garantía, los participantes
          y otros puntos importantes.
        </p>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Monto a financiar</p>
        <p class="info-border-inline mb-6">{{amountFormat}} MXN</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Tasa de interés</p>
        <p class="info-border-inline mb-6">{{anualInterestFormat}}%</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Tasa moratoria</p>
        <p class="info-border-inline mb-6">{{anualInterestFormat}}%</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Plazo de crédito</p>
        <p class="info-border-inline mb-6">{{years}} años</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Plazo de fondeo</p>
        <p class="info-border-inline mb-6">4 semanas</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Fuente de pago</p>
        <p class="info-border-inline mb-6">Ahorro que generará el proyecto</p>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">Su enganche y la garantía.</p>
        <p>La garantía y el enganche son dos puntos importantes.</p>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Enganche</p>
        <p class="info-border-inline mb-6">{{depositPercent}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3 flex">Garantía
          <!-- <feather-icon class="ml-2 cursor-pointer" @click="goToGuaranteeInfo()" title="Ver artículo" icon="HelpCircleIcon" svgClasses="h-5 w-5 text-dark" /> -->
        </p>
        <p class="info-border-inline mb-6">{{ guaranteeFormat }}</p>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">Sus participantes.</p>
        <p>Consulta la cantidad de inversionistas involucrados en el proyecto y la participación que RedGirasol tiene en el mismo.</p>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Inversionistas</p>
        <p class="info-border-inline mb-6">{{ investorsCount }}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Participación de RedGirasol</p>
        <p class="info-border-inline mb-6">{{rgParticipationFormat}}</p>
      </div>
    </div>
  </vx-card>
</template>

<script>
import currencyHelper from '@mixins/currencyHelper';
import formatHelper from '@mixins/formatHelper';
export default {
name: "ApplicantCreditTab",
  props: {
    projectData: { type: Object, required: true },
  },
  mixins: [formatHelper, currencyHelper],
  computed: {
    amountFormat(){
      return this.explicitMoneyFormat(this.projectData.finance.financed_amount, 0);
    },
    anualInterestFormat(){
      return this.currencyFormat(this.projectData.finance.anual_interest *100, 2);
    },
    years(){
      return this.projectData.finance.term / 12;
    },
    depositPercent(){
      return this.decimalPercentFormat(this.projectData.finance.deposit_percentage, 2);
    },
    guaranteeFormat(){
      return `${(this.projectData.finance.total_cost/this.projectData.finance.financed_amount).toFixed(2)} a 1`;
    },
    investorsCount(){
      return this.projectData.project.inversionistas;
    },
    rgParticipationFormat(){
      return this.decimalPercentFormat(this.projectData.project.rg_participation, 2);
    }
  },
  methods: {
    // async goToGuaranteeInfo(){
    //   window.open('https://blog.redgirasol.com/como-decidir-en-cual-proyecto-semilla-invertir/', "_blank");
    // }
  }
}
</script>