<template>
  <div>
    <vx-card class="mt-base" title="Los contratos.">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>Consulta los diferentes contratos que pertenecen al proyecto. Estos documentos son de suma importancia a lo
            largo del plazo del crédito.</p>
        </div>
      </div>
      <div v-if="isMounted">
        <div v-if="hasOldContracts">
          <vs-button v-for="(contract, idx ) in old_contracts" type="border" class="sm:px-base px-4 mr-4 mb-3 mt-4" :key="idx" @click="downloadOldContract(contract.id)">
            {{contract.type.name}}
          </vs-button>
        </div>
        <div class="vx-row mt-6" v-else-if="contracts.length > 0">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center">
              <vs-button v-for="(contract, idx ) in contracts" type="border" class="sm:px-base px-4 mr-4 mb-3" :key="idx" @click="downloadContract(contract.id)">
                {{contract.group.name}}
              </vs-button>
              <vs-button v-if="promissoryNote != null" type="border" class="sm:px-base px-4 mr-4 mb-3" @click="downloadContract(promissoryNote.id)">
                Pagaré
              </vs-button>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="bold mt-5">Los contratos están en proceso de ser firmados, una vez realizado ésto aparecerán en este apartado.</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: "ApplicantProjectContracts",
  props: ['projectId'],
  data(){
    return {
      contracts: [],
      promissoryNote: null,
      sentDate: null,
      signedDate: null,
      old_contracts: null,
      isMounted: false
    }
  },
  async mounted(){
    this.showLoading(true)
    await this.getContracts();
    this.showLoading(false)
  },
  computed: {
    hasOldContracts(){
      return this.old_contracts != null && this.old_contracts.length > 0;
    }
  },
  methods: {
    async getContracts(){
      try {
        this.isMounted = false;
        const response = await axios.get(`/api/v1/get/applicant/project/${this.projectId}/signed-contracts`);
        this.contracts = response.data.contracts;
        this.promissoryNote = response.data.promissory_contract;
        this.old_contracts = response.data.old_contracts;
        this.isMounted = true;
      }
      catch (e) {
      }
    },
    async downloadContract(id){
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId
      };
      this.saveApplicantLogAction("LC20-23",extraParams);
      let url = `${this.ApiDomain}/api/v1/applicant/${this.ApplicantId}/viewProjectContract/${id}?access_token=${this.AccessToken}`;
      window.open(url, "_blank");
    },

    async downloadOldContract(id){
      let url = `${this.ApiDomain}/api/v2/applicant/${this.ApplicantId}/viewProjectContract/${id}?access_token=${this.AccessToken}`;
      window.open(url, "_blank");
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId
      };
      this.saveApplicantLogAction("LC20-23",extraParams);
    }
  }
}
</script>

<style scoped>

</style>