<template>
  <div>
    <vx-card class="px-1 mb-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="card-sub-title">El proyecto semilla.</p>
          <p class="text-2xl mt-1">por {{installerName}}.</p>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col xxl:w-3/4 w-full">
          <p>{{description}}</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "ApplicantCreditHeader",
  props: {
    projectId: { type: [String,Number], required: true },
    projectData: { type: Object, required: true },
    alias: { type: [String], required: true },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    installerName(){
      return this.projectData.installer.tradename || this.projectData.installer.name;
    },
    description(){
      return this.projectData.project.description;
    }
  },
  methods: {
  }
}
</script>