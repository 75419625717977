<template>
    <!-- TABS -->
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" v-model="activeTab" class="tabs-shadow-none" id="project-tabs" :key="isSmallerScreen">
      <!-- TAB CREDITO -->
      <vs-tab label="El crédito" icon-pack="feather" icon="icon-file-text">
        <applicant-credit-tab :project-data="projectData" />
      </vs-tab>

      <!-- TAB PROYECTO -->
      <vs-tab label="El proyecto" icon-pack="feather" icon="icon-pocket">
        <applicant-project-tab :project-data="projectData" :project-id="projectId" />
      </vs-tab>

      <!-- TAB INSTALADOR -->
      <vs-tab v-if="projectData.installer.has_supplier" label="El instalador" icon-pack="feather" icon="icon-shield">
        <applicant-installer-tab :project-data="projectData" :project-id="projectId" />
      </vs-tab>

    </vs-tabs>
</template>

<script>
import ApplicantCreditTab from "@components/applicant/projects/detail/ApplicantCreditTab";
import ApplicantProjectTab from "@components/applicant/projects/detail/ApplicantProjectTab";
import ApplicantInstallerTab from "@components/applicant/projects/detail/ApplicantInstallerTab";

export default {
  name: "ApplicantSeedTabs",
  props: {
    projectId: {type: [String,Number], required: true},
    projectData: { type: Object, required: true },
  },
  components: {
    ApplicantCreditTab,
    ApplicantProjectTab,
    ApplicantInstallerTab,
  },
  data(){
    return {
      minScreen : 1100,
      activeTab: 0,
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < this.minScreen;
    },
  }
}
</script>